export default {
    state: {
        menu: [
            { product: true, title: 'Admin', bgcolor: 'primary' },
            { header: true, title: 'Main Navigation' },
            {
                title: 'Dashboard',
                icon: 'mdi mdi-view-dashboard-outline',
                href: '/admin/dashboard'
            },
            // {
            //   title: 'Analysis', icon: 'mdi mdi-graphql', href: '/admin/monthly',
            // },
            { header: true, title: 'Manage' },
            {
                title: 'Entities',
                icon: 'mdi mdi-account-tie',
                href: '/admin/client',
                right: "client"
            },
            {
                title: 'Users',
                icon: 'mdi mdi-account-supervisor',
                href: '/admin/user',
                right: "user"
            },
            { header: true, title: 'Manage Bots' },
            {
                title: 'Area Master',
                icon: 'mdi mdi-format-list-group',
                href: '/admin/area',
                right: "conserve_area"
            },
            {
                title: 'Input Parameters',
                icon: 'mdi mdi-ballot-outline',
                href: '/admin/inputparameter',
                right: "conserve_input_parameter"
            },
            {
                title: 'File Master',
                icon: 'mdi-file-document-multiple-outline',
                href: '/admin/file',
                right: "conserve_file"
            },
            {
                title: 'Verification',
                icon: 'mdi-certificate-outline',
                href: '/admin/verification',
                right: "conserve_verification"
            },
            {
                title: 'Bots Master',
                icon: 'mdi mdi-graphql',
                href: '/admin/bots',
                right: "conserve_bots"
            },
            { header: true, title: 'Permission' },
            {
                title: 'Rights',
                icon: 'mdi mdi-check-all',
                href: '/admin/rights',
                right: "permission_right"
            },
            {
                title: 'Roles',
                icon: 'mdi mdi-account-cog',
                href: '/admin/roles',
                right: "permission_role"
            },
            { header: true, title: 'Subscription' },
            {
                title: 'Subscription Product',
                icon: 'mdi mdi-graphql',
                href: '/admin/products',
                right: "conserve_bots"
            },
            {
                title: 'Subscription Plan',
                icon: 'mdi mdi-graphql',
                href: '/admin/plans',
                right: "conserve_bots"
            },
        ],
    },
    mutations: {},
    actions: {},
    getters: {

    },
}